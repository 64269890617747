<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>
          Edit Category
        </h3>
      </div>
      <div class="modal-body">
        <div class="modal-body-container">
          <div class="form-group full-width">
            <label for="fieldName">
              Name
            </label>
            <input type="text" class="form-control" id="fieldName" >
          </div>
          <div class="form-group full-width mt-3">
            <label for="fieldType">
              Type
            </label>
            <div class="d-flex" id="fieldType">
              <div class="check-btn" :class="{active: type === 'direct'}" @click="type = 'direct'">
                Direct
              </div>
              <div class="check-btn" :class="{active: type === 'indirect'}" @click="type = 'indirect'">
                Indirect
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start">
        <button class="btn bg-green" @click="saveCategory">
           <span v-if="ui.saving === true"
                 class="spinner-border spinner-border-sm text-light"
                 role="status" aria-hidden="true">
          </span>
          <span class="text-end" v-else>
            Save
          </span>
        </button>
        <button class="btn btn-cancel" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditCategory',
  data() {
    return {
      type: 'direct',
      ui: {
        saving: false
      }
    }
  },
  methods: {
    saveCategory() {
      this.ui.saving = true;

      setTimeout(() => {
        this.ui.saving = false;
      }, 1000)
    }
  }
}
</script>
