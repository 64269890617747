<template>
  <div>
    <div class="page-header d-flex justify-content-between align-items-center">
      <div>
        <h3 class="page-header-title">
          Expenses Categories
        </h3>
      </div>
      <button class="btn btn-primary" @click="modals.addNewCategory = true">
        Add new category
      </button>
    </div>
    <div class="page-content p-normal">
      <div class="row">
        <div class="col-6">
          <div class="search-wrapper">
            <img src="@/assets/icons/icon-search.svg" alt="Icon search">
            <input type="text" placeholder="Search">
          </div>
        </div>
        <div class="col-6">

        </div>
      </div>
      <div class="table">
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Office Lease</td>
              <td>indirect</td>
              <td>
                <button class="btn btn-outline-dark w-25" @click="editCategory('1')">
                  Edit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <transition name="component-fade" mode="out-in">
      <add-new-category v-if="modals.addNewCategory === true" @close="modals.addNewCategory = false" />
    </transition>

    <transition name="component-fade" mode="out-in">
      <edit-category v-if="modals.editCategory === true" @close="modals.editCategory = false" />
    </transition>

  </div>
</template>

<script>
import AddNewCategory from "./modals/AddNewCategory";
import EditCategory from "./modals/EditCategory";
export default {
  name: 'ExpenseCategory',
  components: {EditCategory, AddNewCategory },
  data() {
    return {
      modals: {
        addNewCategory: false,
        editCategory: false
      },
      categoryId: null
    }
  },
  methods: {
    editCategory(id) {
      this.categoryId = id;
      this.modals.editCategory = true;
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  tbody {
    tr {
      vertical-align: middle;
    }
  }
}
</style>
